
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    trianglePosition: String
  }
})
export default class MSpeechBubble extends Vue {
  trianglePosition = "";
  get inputCssVars() {
    return {
      "--triangle-position": this.trianglePosition
    };
  }
}
