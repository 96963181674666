
import MBaseModal from "@/components/MBaseModal.vue";
import MIcon from "@/components/MIcon.vue";
import MButton from "@/components/MButton.vue";
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import firebase from "firebase/app";
// import { updateTutorPrompt } from "@/api/tutor";
// import { updateAdminPrompt } from "@/api/admin";
import {
  getCurrentUnixtime,
  convertToDateFromUnixtime,
  convertToTimeSecFromUnixtime
} from "@/utils/date";
// import { hostname } from "os";

type TutorPrompt = {
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  data: {
    name: string;
    text: string;
    updatedAt?: number;
  };
} | null;

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon
  },
  emits: ["close"]
})
export default class MsChatGPTModal extends Vue {
  isDisplayMeetingId = false;
  prompts: TutorPrompt[] = [null];
  currentPromptId = "";
  domain =
    window.location.hostname === "localhost"
      ? `http://${window.location.host}`
      : `https://${window.location.host}`;
  activeIndex = null;
  accordionItems = [
    {
      title: "Accordion 1",
      content: "This is the content for Accordion 1.",
      contentHeight: 500
    },
    {
      title: "Accordion 2",
      content: "This is the content for Accordion 2.",
      contentHeight: 500
    }
  ];
  urlString = "";

  get validPrompt() {
    return true;
  }

  close() {
    this.$emit("close");
  }

  async selectPrompt(prompt: TutorPrompt) {
    const ref = prompt?.ref;
    await ref?.update({ ...prompt?.data, updatedAt: getCurrentUnixtime() });
    if (!!this.currentPromptId && !!prompt?.ref.id) {
      await ref?.parent.parent?.update({
        selectedPrompt: {
          docId: prompt.ref.id,
          name: prompt.data.name,
          text: prompt.data.text
        }
      });
    }
    window.location.href = `${location.href}`;
  }

  async updatePrompt(prompt: TutorPrompt) {
    const ref = prompt?.ref;
    await ref?.update({ ...prompt?.data, updatedAt: getCurrentUnixtime() });
    if (this.currentPromptId === prompt?.ref.id) {
      await ref?.parent.parent?.update({
        selectedPrompt: {
          docId: prompt.ref.id,
          name: prompt.data.name,
          text: prompt.data.text
        }
      });
    }
    window.location.href = this.urlString;
  }

  async replicatePrompt(prompt: TutorPrompt) {
    const { ref, data } = prompt!;
    const { name, text } = data;

    await ref?.parent.add({
      ...{ name: name + "のコピー", text: text },
      updatedAt: getCurrentUnixtime()
    });
    window.location.href = this.urlString;
  }

  async deletePrompt(prompt: TutorPrompt) {
    if (this.currentPromptId == prompt?.ref.id) {
      alert(
        "利用中のプロンプトは削除できません。別のプロンプトに切り替えてから削除してください。"
      );
    } else {
      const ref = prompt?.ref;
      await ref?.delete();
      // window.location.href = `${location.href}/?isOpenChatGPTConfigModal=true`;
      window.location.href = this.urlString;
    }
  }

  convertDatetime(unixtime: number) {
    return (
      convertToDateFromUnixtime(unixtime) +
      " " +
      convertToTimeSecFromUnixtime(unixtime)
    );
  }

  toggleAccordion(index: any) {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  // setAccordionContentHeight() {
  //   this.$nextTick(() => {
  //     this.accordionItems.forEach((item, index) => {
  //       const contentElement = this.$el.querySelectorAll(".accordion-content")[
  //         index
  //       ];
  //       item.contentHeight = contentElement.scrollHeight;
  //     });
  //   });
  // }

  mounted() {
    this.urlString = Object.keys(this.$route.query).length
      ? `${location.href}&isOpenChatGPTConfigModal=true`
      : `${location.href}/?isOpenChatGPTConfigModal=true`;
  }
  created() {
    if (!store.state.role) {
      alert("ユーザー情報の取得に失敗しました");
      store.commit("SET_IS_OPEN_USER_MODAL", false);
      return;
    }
    const roleType = store.state.role.data.type;
    if (roleType === "tutor" && store.state.tutor && store.state.tutorPrompts) {
      if (store.state.tutorPrompts.length === 0) {
        return;
      } else {
        this.prompts = store.state.tutorPrompts;
        this.currentPromptId = store.state.tutor.main.data.prompt!.docId;
      }
    } else if (
      roleType === "admin" &&
      store.state.admin &&
      store.state.adminPrompts
    ) {
      if (store.state.adminPrompts.length === 0) {
        return;
      } else {
        this.prompts = store.state.adminPrompts;
        this.currentPromptId = store.state.admin.prompt!.docId;
      }
    }
    this.prompts.sort((a, b) => a?.data.updatedAt! - b?.data.updatedAt!);
  }
}
