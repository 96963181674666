
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import { MessageTemplate } from "@/entities/message_template";
import store from "@/store";
import { State } from "@/store/helper";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon
  },
  emits: ["close", "select", "register"]
})
export default class MMessageTemplateSheet extends Vue {
  searchKeyword = "";
  openTemplateIds: string[] = [];

  @State("commomMessageTemplates", "messageTemplateSheet")
  commonTemplates!: MessageTemplate[];
  @State("ownMessageTemplates", "messageTemplateSheet")
  ownTemplates!: MessageTemplate[];
  @State("load", "messageTemplateSheet") load!: boolean;

  get filteredTemplates(): MessageTemplate[] {
    const baseTemplates = [...this.ownTemplates, ...this.commonTemplates];
    if (this.searchKeyword.length === 0) {
      return baseTemplates;
    }
    return baseTemplates.filter(
      template =>
        template.data.heading
          .toUpperCase()
          .includes(this.searchKeyword.toUpperCase()) ||
        template.data.content
          .toUpperCase()
          .includes(this.searchKeyword.toUpperCase())
    );
  }

  changeOpenTemplate(id: string) {
    if (this.openTemplateIds.includes(id)) {
      this.openTemplateIds = this.openTemplateIds.filter(item => item !== id);
    } else {
      this.openTemplateIds.push(id);
    }
  }

  edit(id: string) {
    const matchTemplates = this.ownTemplates.filter(
      template => template.ref.id === id
    );
    if (matchTemplates.length === 0) {
      return;
    }
    store.commit(
      "messageTemplateSheet/SET_IS_OPEN_EDIT_MESSAGE_TEMPLATE_MODAL",
      true
    );
    store.commit(
      "messageTemplateSheet/SET_SELECTED_MESSAGE_TEMPLATE",
      matchTemplates[0]
    );
  }

  select(content: string) {
    this.$emit("select", content);
  }

  register() {
    this.$emit("register");
  }

  close() {
    this.$emit("close");
  }

  created() {
    store.dispatch("messageTemplateSheet/setup");
  }
}
