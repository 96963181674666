
import { Options, Vue } from "vue-class-component";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import { Sticker } from "@/entities/sticker";
import store from "@/store";
import { State } from "@/store/helper";

@Options({
  components: {
    MLoadingIcon
  },
  emits: ["close", "sendSticker"]
})
export default class MStickerSheet extends Vue {
  sendStickerId = "";
  searchKeyword = "";

  @State("stickers", "stickerSheet") stickers!: Sticker[];
  @State("load", "stickerSheet") load!: boolean;

  get filteredStickers() {
    if (this.searchKeyword.length === 0) {
      return this.stickers;
    }

    return this.stickers.filter(sticker =>
      sticker.data.name.includes(this.searchKeyword)
    );
  }

  get dynamicButtonCss() {
    if (this.sendStickerId.length === 0) {
      return {
        "bg-gray-300": true,
        "cursor-default": true
      };
    } else {
      return {
        "bg-primary-500": true,
        "hover:bg-primary-600": true,
        "cursor-pointer": true
      };
    }
  }

  selectSticker(id: string) {
    this.sendStickerId = id;
  }

  sendSticker() {
    const matchStickers = this.stickers.filter(
      item => item.ref.id === this.sendStickerId
    );
    if (matchStickers.length > 0) {
      this.$emit("sendSticker", matchStickers[0].data.imageUrl);
      this.close();
    }
  }

  close() {
    this.$emit("close");
  }

  created() {
    store.dispatch("stickerSheet/setup");
  }
}
